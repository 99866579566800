.header {
    height: 60px;
    width: 100%;
    z-index: 3;
    position: fixed;
    top: 0;

    box-shadow: 1px 1px 13px -2px #c9cace;
    background-color: #fff;
}

.header span {
    color: #333333;
}

.header-option {
    height: 60px;
    width: 10%;
    padding-top: 13px;
    box-sizing: border-box;
    float: left;
    text-align: center;
}

.header-heading {
    height: 60px;
    width: 80%;
    box-sizing: border-box;
    float: left;
    text-align: center;
    font-size: 14px;
    padding-top: 10px;
    margin-left: -1%;
    margin-top: 2px;
}

.header-heading h3 {
    color: #333333;
}


.content {
    box-sizing: border-box;
    text-align: center;
    padding-top: 64px;
    padding-left: 2%;
    padding-right:2%;
    padding-bottom: 64px;
    margin: 0px auto;
    width: 100%;
    height: 100%;

    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;

    background-color: #f7f8fd;
    font-family: 'Montserrat', sans-serif;
}

.content p:first-of-type {
    margin-bottom: 0px;
    word-break: break-all
}

.content p {
    margin-top: 5px;
    font-size: 12px;
    color: #333333;
}


.sidenav {
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0; 
    left: 0;
    overflow-x: hidden;
    padding-top: 60px;
    transition: 0.5s; 
    border-image: initial;
    border: none;

    background-color: #fff;
    box-shadow: -3px 5px 19px 0px #c9cace;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    display: block;

    color: #9a9a9a;
}

.sidenav a:hover {
    color: #9a9a9a;
}

.sideNavBox{
    margin-top: 30px;
}

.content-row {
    display: block;
    margin-bottom: 0;
    height: 100%;
    margin-left: 30px;
    margin-top: 20px;
    box-sizing: border-box;
}

.content-row-icon {
    height: 40px;
    display: block;
    /* border-radius: 5px; */
    width: 40px;
    float: left;
    text-align: center;
    padding: 3px;

    box-shadow: 1px 1px 13px -2px #c9cace;
    background-color: #fff;
    border: 1px solid #0000000a;
}

.content-row-title {
    padding-top: 1px;
    padding-left: 0px;
    margin-left: 0px;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    height: 100%;
}

.content-row-title p {
    padding-left: 89px;
    font-weight: 100;
    letter-spacing: 0.5px;

    color: #777777;
}

.content-row-icon img{
    margin-top: 5px
}


.content-container {
    padding: 5px;
    box-sizing: border-box;
    height: auto;
    width: 33.33%;
    float: left;
}

.content-box-icon { 
    padding-top: 15px;
    padding-bottom: 15px;
    /* border-radius: 5px; */
    height: auto;
    width: 100%;
    float: left;
    text-align: center;
    box-sizing: border-box;
    letter-spacing: 1px;

    background-color: #fff;
    box-shadow: 1px 1px 13px -2px #c9cace;
}

.content-box-icon p {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 600;

    color: #777777 !important;
}


.footer {
    width: 100%;
    height: 40px;
    position: fixed;
    bottom: 0;

    box-shadow: 1px -1px 13px -2px #c9cace;
    background-color: #fff;
}

.footer span {
    color: #333333;
}

.footer-option {
    width: 25%;
    height: 40px;
    float: left;
    box-sizing: border-box;
    padding: 15px;
    padding-top: 5px;
    text-align: center;
    font-size: 20px;
}