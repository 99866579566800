.particles {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0px;
    left: 0px;
    background-image: linear-gradient( #005290 ,white);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}