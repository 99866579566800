.sidenav {
    height: 100%;
    position: fixed;
    z-index: 3;
    top: 0; 
    left: 0;
    overflow-x: hidden;
    padding-top: 80px;
    transition: 0.3s; 
    background-color: #fff;
    box-shadow: -3px 5px 19px 0px #e5e5e5;
}

.sidenav span{
    margin: 15px;
    color: #1c9bd9;
}

/* .sidenav_item{
    font-size: 16px;
    margin-top: 10px;
} */