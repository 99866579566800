.form {
    width: 70%;
    margin: 0 auto;
    margin-top: 20px;
    box-sizing: border-box;
    text-align: center;
}

.form input[type=text], 
.form input[type=password] {
    width: 100%;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-top: 5px;
    padding: 10px;
    background: transparent;
    font-size: 12px;
    color: #fff;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.form input[type=text]::placeholder,
.form input[type=password]::placeholder {
    color: #fff;
    letter-spacing: 1px;
    opacity: 1;
}

.form button {
    color: white;
    width: 100%;
    background-color: #fe9c48;
    border: 2px solid #fe9c48;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    letter-spacing: 2px;
    margin-top: 5px;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

.form a {
    color: #fff;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    margin: 0px;
    padding: 0px;
    text-decoration: underline;
}

.form p {
    color: #fff;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    margin: 0px;
    margin-top: 15px;
    padding: 0px;
}

.heading {
    width: 100%;
    margin-top: 40%;
    text-align: center;
    box-sizing: border-box;
}

.heading h1 {
    color: #fff;
    font-family: 'Righteous', cursive;
    letter-spacing: 2px;
    margin: 0px;
    padding: 0px;
}

.heading p {
    color: #fff;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 2px;
    margin: 0px;
    padding: 0px;
}