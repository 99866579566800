.footer {
    width: 100%;
    height: 40px;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 1px -1px 13px -2px #e5e5e5;
    background-color: #fff;
}

.footer-option {
    width: 25%;
    height: 40px;
    float: left;
    box-sizing: border-box;
    padding: 15px;
    padding-top: 8px;
    text-align: center;
    font-size: 20px;
}

.footer-option span {
    color: #333333;
}