.header {
    height: 70px;
    width: 100%;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 1px 1px 13px -2px #e5e5e5;
    background-color: #fff;
    padding-right: 5px;
    box-sizing: border-box;
}

.header-option {
    height: 70px;
    width: 10%;
    padding-top: 30px;
    box-sizing: border-box;
    float: left;
    text-align: center;
}

.header-option span {
    color: #333;
}

.header-heading {
    height: 60px;
    width: 80%;
    box-sizing: border-box;
    float: left;
    text-align: center;
    padding-top: 12px;
}

.header-heading h3 {
    color: #333;
    font-family: 'Montserrat', cursive;
    /* font-family: 'Roboto', sans-serif; */
    font-weight: bold;
    letter-spacing: 1px;
}